const Path = {
  Slash: "/",
  Auth: "/auth",
  Dashboard: "/dashboard",
  Downloads: "/downloads",
  MyApproval: "/my-approval",
  MyApprovalList: "/my-approval/list",
  Pricing: "/pricing",
  WholesalePrice: "/pricing/wholesale-price",
  EditWholesalePrice: "/pricing/edit-wholesale-price",
  Payments: "/payments",
  PaymentsList: "/payments/list",
  Inventory: "/inventory",
  InventoryList: "inventory/list",
  Admin: "/admin",
  Seaoil: "/seaoil",
  Pricelocq: "/pricelocq",
  PricelocqConsumer: "/pricelocq-consumer",
  PricelocqBusiness: "/pricelocq-business",
  Campaigns: "/campaigns",
  CustomersList: "/accounts/customer/list",

  VoucherCampaign: "/campaigns/list",
  AddVoucherCampaign: "/campaigns/add",
  ViewVoucherCampaign: "/campaigns/view",
  ViewVoucherCampaignDetails: `/campaigns/:id`,
  ViewVoucherCampaignId: (id) => `/campaigns/${id}`,
  EditVoucherCampaignDetails: `/campaigns/:id/edit`,
  EditVoucherCampaignId: (id) => `/campaigns/${id}/edit`,
  EditVoucherCampaign: "/campaigns/edit",
  Vouchers: "/campaigns/vouchers",
  ConsumerOtherServices: "/other-services",
  KYCSubmissions: "/accounts/kyc",
  ViewKYCSubmissions: `/accounts/kyc/view/:id`,
  ViewKYCSubmissionsId: (id) => `/accounts/kyc/view/${id}`,
  ConsumerRFID: "/other-services/rfid",
  SettlementHistory: "/payments/settlement/history",
  FleetAccounts: `/accounts/fleet/list`,
  ViewFleetDetails: `/accounts/fleet/:id`,
  ViewFleetDetailsById: (id, plbType) => `/accounts/fleet/${id}/${plbType}`,
  ViewFleetAccount: `/accounts/fleet/:id/info/view`,
  // ViewFleetAccountById: (id, plbType) => `/accounts/fleet/${id}/${plbType}/info/view`,
  ViewFleetAccountById: (id, plbType) => `/accounts/fleet/${id}/info/view`,
  ViewFleetPricing: `/accounts/fleet/:id/pricing/view`,
  ViewFleetPricingById: (id) => `/accounts/fleet/${id}/pricing/view`,
  AddFleetAccount: `/accounts/fleet/add`,
  EditFleetAccount: `/accounts/fleet/:id/info/edit`,
  EditFleetAccountById: (id) => `/accounts/fleet/${id}/info/edit`,
  Accounts: "/accounts",
  Inbox: "/inbox",
  ViewSettlementHistoryById: (id) => `/payments/settlement/history/${id}/view`,
  ViewSettlementHistory: "/payments/settlement/history/:id/view",
  PricingChanges: "/pricing/pricing-changes",
  BatchPricingChanges: "/pricing/pricing-changes/batch",
  DoxNegotiatedDiscounts: "/pricing/dox-negotiated-discounts",
  FleetPrices: "/pricing/fleet",
  PremiumPayout: "/payments/premium-payout",
  ViewPremiumPayoutByMonthYear: (month, year) => `/payments/premium-payout/${month}/${year}/view`,
  ViewPremiumPayout: "/payments/premium-payout/:month/:year/view",
  Station: "/accounts/station",
  AddStation: "/accounts/station/add",
  EditStation: "/accounts/station/:id/edit",
  EditStationId: (id) => `/accounts/station/${id}/edit`,
  ViewStation: "/accounts/station/:id",
  ViewStationId: (id) => `/accounts/station/${id}`,
  AccessAuthorization: "/access-authorization",
  UserAccess: "/access-authorization/user-access",
  ActivateAccount: "/activate-account",
  LubeServOnWheelsBookings: "/other-services/lubeserv-on-wheels-bookings",
  LubeServOnSiteBookings: "/other-services/lubeserv-on-site-bookings",
  PLCCashIn: "/payments/plc-cash-in",
  PLBCashIn: "/payments/plb-cash-in",
  ConsumerFuelPage: "/fuel",
  PLCRedemption: "/fuel/plc-redemption",
  PLCLOCQPayForFuel: "/fuel/plc-locqpay-for-fuel",
  PLBPayWithLOCQPayRedemption: "/fuel/plb-pay-with-locqpay-redemption",
  PLCManualRedemption: "/fuel/manual-redemption",
  PLBRedemption: "/fuel/plb-redemption",
  PriceBuildUp: "/pricing/price-build-up",
  CardManagement: "/accounts/card-management",
  VIPHistoryAwardPoints: "/vip/transaction-history/award-points",
  VIPHistoryPayWithPoints: "/vip/transaction-history/pay-with-points",
  VIPHistoryRedeemPoints: "/vip/transaction-history/redeem-points",
  VIPTransactionHistory: "/vip/transaction-history",
  VIPTransactionHistoryManualPointsAwarding:
    "/vip/transaction-history/award-points/manual-points-awarding",
  NonFuel: "/other-services/non-fuel/:platformType",
  NonFuelPLC: "/other-services/non-fuel/plc",
  NonFuelPLB: "/other-services/non-fuel/plb",
  RFID: "/other-services/rfid",
  LOCQPayNonFuelPLB: "/other-services/plb-non-fuel",
  LOCQPayNonFuelPLC: "/other-services/plc-non-fuel",
  ManualCashIn: "/payments/plb-cash-in/manual",
};

export default Path;
