import moment from "moment";
import { formatNumber, parseNumber } from "./number.utils";
import {
  isAlphaNumericWithSymbols,
  isAlphaNumeric as checkIsAlphaNumeric,
  isEmailValid,
  isNumeric as checkIsNumeric,
} from "./text.utils";

export const validateField = (field = {}, form) => {
  const { type } = field;

  const { value, validations = {}, isDisabled } = { ...field };
  if (isDisabled && isDisabled(field, form)) {
    return {
      error: false,
      messages: [],
    };
  }
  const { custom } = validations;
  let isCustomError = false;

  const { forceShowError, ...validateResult } = validateValue(validations, value);

  let error = validateResult.error;
  const messages = validateResult.messages;

  if (custom && Array.isArray(custom) && ((!error && type === "input") || type !== "input")) {
    custom.forEach((validation) => {
      const state = validation(value, field, form);
      if (state.error) {
        error = true;
        isCustomError = true;
      }
      if (state.message) messages.push(state.message);
    });
  }

  return {
    error,
    messages,
    isCustomError,
    forceShowError,
  };
};

export const validateValue = (field, value) => {
  const messages = [];
  let error = false;
  const {
    isRequired = false,
    isNumber: isNumberValidate = false,
    isRequiredArray = false,
    isEmail = false,
    isDateTime = false,
    isAlphaNumericName = false,
    isAlphaNumeric = false,
    isNumeric = false,
    minimum = null,
    maximum = null,
    isNoSpace = false,
  } = field || {};
  let forceShowError = false;
  const validation = [
    {
      isEnabled: isRequired,
      condition: value && value?.toString().trim().length !== 0,
      message: "This field is required",
    },
    {
      name: "isNumber",
      isEnabled: isNumberValidate,
      condition: value?.length > 0 ? !!String(value) && !isNaN(value) : true,
      // condition:
      //   value && value?.toString().trim().length !== 0
      //     ? parseNumber(value)
      //       ? isNumber(parseNumber(value))
      //       : false
      //     : true,
      message: "",
    },
    {
      isEnabled: isRequiredArray,
      condition: Array.isArray(value) && value?.length > 0,
      message: "This field is required",
    },
    {
      isEnabled: isEmail,
      condition:
        value && value?.toString().trim().length !== 0 ? isEmailValid(value?.toString()) : true,
      message: "Please enter a valid email address",
    },
    {
      isEnabled: isDateTime,
      condition: value ? moment(value).isValid() : true,
      message: "Invalid format",
    },
    {
      isEnabled: isAlphaNumericName,
      condition: value ? isAlphaNumericWithSymbols(value) : true,
      message: "",
    },
    {
      isEnabled: isAlphaNumeric,
      condition: value ? checkIsAlphaNumeric(value) : true,
      message: "",
    },
    {
      isEnabled: isNoSpace,
      condition: value ? !value.toString().includes(" ") : true,
      message: "",
    },
    {
      name: "isNumeric",
      isEnabled: isNumeric,
      condition: value ? checkIsNumeric(value) : true,
      message: "",
    },
    {
      isEnabled: minimum !== null,
      condition:
        value && !isNaN(parseInt(value)) && parseNumber(value)
          ? parseNumber(value) >= minimum
          : true,
      message: `Minimum of ${formatNumber(minimum, 0)}`,
      forceShowError: true,
    },
    {
      isEnabled: maximum !== null,
      condition:
        value && !isNaN(parseInt(value)) && parseNumber(value)
          ? parseNumber(value) <= maximum
          : true,
      message: `Maximum of ${formatNumber(maximum, 0)}`,
      forceShowError: true,
    },
  ];

  validation.forEach(({ isEnabled, condition, message, ...p }) => {
    if (isEnabled && !condition) {
      error = true;
      messages.push(message);
      if (p.forceShowError) {
        forceShowError = true;
      }
    }
  });

  return {
    error,
    messages,
    forceShowError,
  };
};
