import { getKYCSubmissionById, updateKYCSubmissionById } from "apis/kyc-submission.api";
import {
  ActionButton,
  BackLink,
  Checkbox,
  Field,
  Image,
  Intro,
  Modal,
  Pill,
  Text,
  TextField,
  Title,
} from "components/commons";
import { useApi, useModal, useMount, useRouter } from "hooks";
import React, { useCallback, useState, useContext } from "react";
import locale from "localization";
import { DateTime, Path, Role, Portal } from "enums";
import styles from "./view-kyc.module.scss";
import { prettifyKYCSubmissionStatus } from "utils/pretty.utils";
import KYCSubmissionStatus from "enums/kyc-submission-status";
import { formatDate } from "utils";
import { EditRounded, ImageOutlined } from "@material-ui/icons";
import PreviewModal from "components/commons/preview-modal/preview-modal";
import { Skeleton } from "@material-ui/lab";
import { UserContext } from "contexts";

const ViewKYC = () => {
  const { query } = useRouter();
  const [remarks, setRemarks] = useState("");
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(false);
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.OILCO;
  });

  const { result, request, loading } = useApi({
    api: getKYCSubmissionById,
    params: {
      id: query.id,
    },
    pageError: true,
  });

  const updateKYC = useApi({
    api: updateKYCSubmissionById,
    params: {
      id: query.id,
      remarks,
    },
  });

  const viewImageModal = useModal();
  const viewImage2Modal = useModal();

  const notifModal = useModal();
  const getImageName = useCallback((image) => {
    if (image) {
      const filePart = image.split("/");
      return filePart?.[filePart?.length - 1];
    }
    return "";
  }, []);

  const status = result?.verificationStatus;
  const isEditable = ![
    KYCSubmissionStatus.Pending,
    KYCSubmissionStatus.Deleted,
    KYCSubmissionStatus.Expired,
  ].includes(status);
  // const isDeleted = status === KYCSubmissionStatus.Deleted;
  const company = result?.company;
  let companiesWithoutMobileNumber = [
    "Angkas",
    "Grab",
    "MPT DriveHub",
    "Automobile Association Philippines (AAP)",
    "Lalamove",
    "Others",
  ];
  let showImageUrl = ["Lalamove", "Others", "Grab"];
  const isAAP = result?.company.includes("AAP") || result?.company === "Grab";
  const isMPT = result?.company.includes("MPT") || result?.company === "Grab";
  const shouldShowImageUrl = showImageUrl.includes(result?.company);
  const showCompanyNumber =
    companiesWithoutMobileNumber.includes(result?.company) ||
    result?.company === "Borzo" ||
    result?.company === "J&T Express" ||
    result?.company === "Ninja Van"
      ? []
      : [locale.companyMobileNumber, "companyMobileNumber"];
  let secondImageText = "";
  if (isAAP) {
    switch (result?.company) {
      case "Grab":
        secondImageText = locale.externalIds;
        break;
      default:
        secondImageText = locale.externalIdsAAP;
        break;
    }
  } else {
    switch (company) {
      case "Grab":
        secondImageText = locale.externalIds;
        break;
      case "MoveIt":
        secondImageText = locale.externalIdsMoveIt;
        break;
      case "Foodpanda":
        secondImageText = locale.externalIdsFoodPanda;
        break;
      default:
        secondImageText = "";
    }
  }
  const showSuccessUpdateModal = useCallback(() => {
    notifModal.show({
      title: locale.exclamatedSuccess,
      content: locale.detailHaveBeenSuccessfullyUpdated,
      submitText: locale.gotIt,
      noCancelButton: true,
      notif: true,
      submit: () => {
        notifModal.close();
      },
    });
  }, [notifModal]);

  const approveKyc = useCallback(() => {
    notifModal.show({
      title: locale.approveKYCrequest,
      content: locale.areYouSureAprroveKYC,
      approve: true,
      submitText: locale.yesApproveRequest,
      submit: async () => {
        await updateKYC.request({
          verificationStatus: KYCSubmissionStatus.Approved,
        });
        setEdit(false);
        showSuccessUpdateModal();
        request();
      },
    });
  }, [notifModal, request, updateKYC, showSuccessUpdateModal]);

  const rejectKyc = useCallback(() => {
    notifModal.show({
      title: locale.rejectKYCRequest,
      content: locale.areyouSureYouWantToRejectKYC,
      approve: false,
      submitText: locale.yesRejectRequest,
      submit: async (remarks) => {
        await updateKYC.request({
          verificationStatus: KYCSubmissionStatus.Rejected,
          remarks,
        });
        setEdit(false);
        showSuccessUpdateModal();
        request();
      },
    });
  }, [notifModal, request, updateKYC, showSuccessUpdateModal]);

  useMount(() => {
    request();
  });

  return (
    <div className={styles.container}>
      <BackLink text={locale.kycSubmissions} path={Path.KYCSubmissions} />
      <div
        style={{
          display: "flex",
        }}
      >
        {role === Role.Accounting ? (
          <Intro title={locale.kycDetails} />
        ) : (
          <Intro
            style={{
              minWidth: edit ? "300px" : "100%",
            }}
            title={locale.kycDetails}
            actionText={isEditable && !edit && !loading ? locale.edit : null}
            actionOutlined
            actionStartIcon={<EditRounded />}
            actionOnClick={
              !edit
                ? () => {
                    setEdit(true);
                    setValue(status === KYCSubmissionStatus.Approved);
                  }
                : null
            }
          />
        )}

        {edit && (
          <ActionButton
            right
            style={{
              marginTop: "0px",
            }}
            loading={updateKYC.loading}
            items={[
              {
                onClick: () => {
                  setEdit(false);
                },
                text: locale.cancel,
                primary: false,
              },
              {
                disabled: (status === KYCSubmissionStatus.Approved) === value,
                onClick: async () => {
                  if (value) {
                    approveKyc();
                  } else {
                    rejectKyc();
                  }
                },
                text: locale.save,
                primary: true,
              },
            ]}
          />
        )}
      </div>
      <div>
        <Title black small>
          {locale.personalInformation}
        </Title>
      </div>
      <div>
        <table className={styles.table}>
          <tbody>
            {[
              [
                locale.status,
                "status",
                () => {
                  const s = edit
                    ? value
                      ? KYCSubmissionStatus.Approved
                      : KYCSubmissionStatus.Rejected
                    : status;
                  const pill = {
                    sky: s === KYCSubmissionStatus.Pending,
                    grass: s === KYCSubmissionStatus.Approved,
                    deepRed:
                      s === KYCSubmissionStatus.Rejected || s === KYCSubmissionStatus.Expired,
                    cement: s === KYCSubmissionStatus.Deleted,
                  };
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {edit && (
                        <Checkbox
                          value={value}
                          onChange={(name, { value }) => {
                            setValue(value);
                          }}
                          switchMode
                          colored
                          disabled={status === KYCSubmissionStatus.Expired}
                        />
                      )}
                      {edit ? (
                        <Text subtitle strong>
                          {prettifyKYCSubmissionStatus(s)}
                        </Text>
                      ) : (
                        <Pill {...pill}>{prettifyKYCSubmissionStatus(s)}</Pill>
                      )}
                    </div>
                  );
                },
              ],
              ...(status === KYCSubmissionStatus.Rejected ? [[locale.remarks, "remarks"]] : []),
              [
                locale.dateApplied,
                "appliedAt",
                () => {
                  const date = result?.appliedAt;
                  return formatDate(date, DateTime.R);
                },
              ],
              [locale.firstName, "firstName"],
              [locale.middleName, "middleName"],
              [locale.lastName, "lastName"],
              [locale.mobileNumber, "mobileNumber"],
              [locale.emailAddress, "email"],
              [locale.driverLicenseIdIDNumber, "driversLicense"],
              [locale.company, "company"],
              showCompanyNumber,
              [locale.companyName, "companyName"],
              [
                isAAP
                  ? locale.mebershipID
                  : isMPT
                  ? `${locale.uniqueID}/${locale.referralCode}`
                  : locale.riderId,
                "riderId",
              ],
            ].map(([label, key, renderValue], index) => {
              return (
                ((result && result[key]) || renderValue) && (
                  <tr key={index}>
                    <td className={styles.label}>
                      <Text label>{label}</Text>
                    </td>
                    <td>
                      <Text
                        strong
                        style={{
                          color: "#4c69a4",
                        }}
                      >
                        {loading ? (
                          <Skeleton
                            style={{
                              width: "200px",
                            }}
                          />
                        ) : renderValue ? (
                          renderValue()
                        ) : (
                          result && result[key]
                        )}
                      </Text>
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
      </div>
      <div>
        {(!isMPT || shouldShowImageUrl) && (
          <Title black small>
            {locale.personalDocuments}
          </Title>
        )}
      </div>

      <div>
        {(!isMPT || shouldShowImageUrl) && (
          <div
            className={styles.image}
            onClick={() => {
              viewImageModal.show();
            }}
          >
            <div className={styles.src}>
              <Image src={result?.imageUrl} className={styles.photo} loading={loading} />
            </div>
            <div className={styles.imageName}>
              {loading ? (
                <Skeleton
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                getImageName(result?.imageUrl2)
              )}
            </div>
          </div>
        )}
      </div>
      <div>
        {result?.imageUrl2 && (
          <>
            <Title black small>
              {secondImageText}
            </Title>

            <div
              className={styles.image}
              onClick={() => {
                viewImage2Modal.show();
              }}
            >
              <div className={styles.src}>
                <Image src={result?.imageUrl2} className={styles.photo} loading={loading} />
              </div>
              <div className={styles.imageName}>
                {loading ? (
                  <Skeleton
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  getImageName(result?.imageUrl2)
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <PreviewModal
        {...viewImageModal}
        title={
          <div
            className="flex items-center"
            style={{
              color: "#fff",
            }}
          >
            <ImageOutlined
              style={{
                color: "#fff",
                marginRight: "10px",
              }}
            />
            {loading ? (
              <Skeleton
                style={{
                  width: "200px",
                }}
              />
            ) : (
              getImageName(result?.imageUrl)
            )}
          </div>
        }
      >
        <Image src={result?.imageUrl} className={styles.viewPhoto} />
      </PreviewModal>
      {result?.imageUrl2 && (
        <PreviewModal
          {...viewImage2Modal}
          title={
            <div
              className="flex items-center"
              style={{
                color: "#fff",
              }}
            >
              <ImageOutlined
                style={{
                  color: "#fff",
                  marginRight: "10px",
                }}
              />
              {loading ? (
                <Skeleton
                  style={{
                    width: "200px",
                  }}
                />
              ) : (
                getImageName(result?.imageUrl2)
              )}
            </div>
          }
        >
          <Image src={result?.imageUrl2} className={styles.viewPhoto} />
        </PreviewModal>
      )}

      <Modal {...notifModal}>
        <div className={styles.modalContent}>
          <Text>{notifModal.content}</Text>
        </div>
        {!notifModal.approve && !notifModal.noCancelButton && (
          <div>
            <Field label={locale.reasonRemarks}>
              <TextField
                multiline
                value={remarks}
                onChange={(name, { value }) => {
                  setRemarks(value);
                }}
              />
            </Field>
          </div>
        )}
        <ActionButton
          right={!notifModal.noCancelButton}
          center={notifModal.noCancelButton}
          loading={updateKYC.loading}
          items={[
            ...(!notifModal.noCancelButton
              ? [
                  {
                    onClick: () => {
                      notifModal.close();
                    },
                    text: locale.cancel,
                    primary: false,
                  },
                ]
              : []),
            {
              disabled: !notifModal.notif && !notifModal.approve && !remarks.trim(),
              onClick: async () => {
                await notifModal.submit(remarks);
                // notifModal.close();
              },
              text: notifModal.submitText,
              primary: true,
            },
          ]}
        />
      </Modal>
      {status === KYCSubmissionStatus.Pending && (
        <div
          style={{
            marginTop: "60px",
          }}
        >
          <ActionButton
            loading={updateKYC.loading}
            items={[
              {
                onClick: () => {
                  approveKyc();
                },
                text: locale.approve,
                primary: true,
              },
              {
                onClick: () => {
                  rejectKyc();
                },
                text: locale.reject,
                primary: false,
                outline: true,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default ViewKYC;
