import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import CashInModule from "modules/plb/cashin/cashin.module";
import ManualCashinModule from "modules/plb/cashin/manual-cashin.module";

const PLBCashInPage = () => {
  return (
    <Switch>
      <Route exact path={Path.ManualCashIn} component={ManualCashinModule} />
      <Route exact path={Path.PLBCashIn} component={CashInModule} />
      <Redirect to={Path.PLBCashIn} />
    </Switch>
  );
};

export default PLBCashInPage;
