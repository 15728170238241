import React from "react";
import { Autocomplete, DateRange, Field, SearchField, Filter, Button } from "components/commons";

import { AccountStatus } from "enums";
import locale from "localization";
import { prettifyAccountStatus } from "utils";

import styles from "./card-list-filter.module.scss";

const CardListFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
  getUserAccess,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          submit={applyFilter}
          clear={applyClearFilter}
          close={applyResetFilter}
          filterCount={filterCount}
        >
          <div
            style={{
              padding: "0px 20px",
            }}
          >
            <Field
              className={styles.date}
              label={locale.dateProcured}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDate,
                    endDate,
                  });
                }}
              />
            </Field>
            {/* <Field
              className={styles.filterInput}
              label={locale.stationsProcured}
              labelPosition={"left"}
              childrenClassName={styles.fieldContentStation}
            >
              <SelectStation
                {...filterState?.stationIds}
                onChange={(_, { value, label }) => {
                  modifyFilter({ stationIds: { value, label } });
                }}
              />
            </Field> */}
            <Field
              className={styles.filterInput}
              label={locale.status}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                name="loyaltyCardStatuses"
                value={filterState.loyaltyCardStatuses}
                options={[
                  { label: locale.all, value: "all" },
                  {
                    label: prettifyAccountStatus(AccountStatus.Registered),
                    value: AccountStatus.Registered,
                  },
                  {
                    label: prettifyAccountStatus(AccountStatus.UnRegistered),
                    value: AccountStatus.UnRegistered,
                  },
                  {
                    label: prettifyAccountStatus(AccountStatus.Deactivated),
                    value: AccountStatus.Deactivated,
                  },
                  {
                    label: prettifyAccountStatus(AccountStatus.Expired),
                    value: AccountStatus.Expired,
                  },
                ]}
                onChange={(name, { value }) => modifyFilter({ [name]: value })}
              />
            </Field>

            <Field
              className={styles.date}
              label={locale.date}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <DateRange
                value={{
                  startDate: filterState.startDateUpdated,
                  endDate: filterState.endDateUpdated,
                }}
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDateUpdated: startDate,
                    endDateUpdated: endDate,
                  });
                }}
              />
            </Field>
          </div>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={locale.vipCardFilterSearch}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
            restrict={true}
            searchWithSymbols={true}
          />
        </Field>
        <Field className={styles.downloadButton}>
          {(getUserAccess?.fullAccess || getUserAccess?.exportAccess) && (
            <Button
              startIcon={<span className="icon-download" />}
              exportButton
              onClick={exportReport}
              loading={exporting}
            >
              {locale.export}
            </Button>
          )}
        </Field>
      </div>
    </div>
  );
};

export default CardListFilter;
