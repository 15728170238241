import { createManualCashIn, getFleetByName } from "apis";
import {
  BackLink,
  Button,
  Checkbox,
  Dropdown,
  Field,
  Intro,
  Text,
  TextField,
} from "components/commons";
import { InputAmount } from "components/field";
import { ConfirmModal } from "components/modals";
import { CashInChannel, Path } from "enums";
import { useApi, useForm, useModal, useRouter } from "hooks";
import locale from "localization";
import { useEffect } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { debounced, handleRequest, parseNumber } from "utils";
import { prettifyCashInChannel } from "utils/pretty.utils";
import { formatAmount, isAlphaNumeric } from "utils/text.utils";
import styles from "./manual-cashin.module.scss";
import { initialState } from "./manual-cashin.state";

const ManualCashinModule = () => {
  const confirmModal = useModal();

  const { history } = useRouter();

  const [fleet, setFleet] = useState();

  const { request: createManualCashInRequest, loading: creatingManualCashIn } = useApi({
    api: createManualCashIn,
  });

  const { request: getFleetRequest, loading: loadingFleetDetails } = useApi({
    api: getFleetByName,
    handleOwnError: true,
  });

  const {
    fields = {},
    modifyField,
    submitForm,
    isFormSubmittable,
    getFormValues,
    clearForm,
    applyFieldErrors,
  } = useForm({
    initialState,
  });

  const fetchFleetDetails = useCallback(
    async (params) => {
      try {
        const res = await getFleetRequest(params);
        if (res && res.status !== "active") {
          applyFieldErrors({
            businessId: "Business ID deactivated, expired, or not found.",
          });
        } else {
          setFleet(res);
        }
      } catch (e) {
        applyFieldErrors({
          businessId: "Business ID deactivated, expired, or not found.",
        });
      }
    },
    [getFleetRequest, applyFieldErrors]
  );

  const {
    businessId,
    cashInAmount,
    cashInFeeLocq,
    cashInFeeCustomer,
    cashInChannel,
    referenceNumber,
    businessName,
    deductCustomerFee,
    remarks,
  } = fields;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    debounced(async () => {
      if (!businessId.error && businessId.value?.trim()) {
        fetchFleetDetails({ name: businessId.value });
      }
    }, 1000),
    [businessId]
  );

  const onChangeBusinessIdCb = useCallback(
    async (fieldName, { value }) => {
      if (isAlphaNumeric(value)) {
        modifyField(fieldName, { value });
        setFleet(null);
        // if (!error) {
        //   fetchFleetDetails({ name: value });
        // }
      }
    },
    [modifyField]
  );

  const cashInAmountTotalValue = useMemo(() => {
    let deductedCashinAmount = deductCustomerFee.value
      ? parseNumber(cashInAmount.value) - parseNumber(cashInFeeCustomer.value)
      : parseNumber(cashInAmount.value);

    if (deductedCashinAmount < 0) deductedCashinAmount = 0;
    return deductedCashinAmount;
  }, [cashInAmount, cashInFeeCustomer, deductCustomerFee]);

  const submit = useCallback(() => {
    const formValues = getFormValues();

    handleRequest(async () => {
      if (fleet) {
        await createManualCashInRequest({
          fleetId: fleet.fleetId,
          amount: parseNumber(cashInAmountTotalValue),
          locqFee: parseNumber(formValues.cashInFeeLocq),
          customerFee: parseNumber(formValues.cashInFeeCustomer),
          cashinChannel: formValues.cashInChannel,
          referenceNumber: formValues.referenceNumber,
          remarks: formValues.remarks,
        });
        confirmModal.show({
          title: locale.manualCashInCreated,
          content: (
            <locale.Populate
              text={locale.youHaveSuccessfullyCredited}
              items={[<b>{formatAmount(cashInAmountTotalValue)}</b>, <b>{fleet.businessName}</b>]}
            />
          ),
          primary: {
            onClick: () => {
              clearForm();
              confirmModal.close();
              history.push(Path.PLBCashIn);
            },
          },
        });
      }
    });
  }, [
    history,
    confirmModal,
    cashInAmountTotalValue,
    clearForm,
    createManualCashInRequest,
    fleet,
    getFormValues,
  ]);

  const confirm = useCallback(() => {
    confirmModal.show({
      title: `${locale.createManualCashIn}?`,
      content: (
        <locale.Populate
          text={locale.youAreAboutToCredit}
          items={[<b>{cashInAmountTotalValue}</b>, <b>{fleet?.businessName}</b>]}
        />
      ),
      primary: {
        text: locale.create,
        onClick: () => {
          submit();
        },
      },
      secondary: {
        text: "Cancel",
        onClick: () => {
          confirmModal.close();
        },
      },
    });
  }, [cashInAmountTotalValue, fleet?.businessName, confirmModal, submit]);

  return (
    <div>
      <ConfirmModal {...confirmModal} loading={creatingManualCashIn} />
      <BackLink text={locale.cashIn} path={Path.PLBCashIn} />
      <Intro title={locale.createManualCashIn}></Intro>
      <div>
        <Field
          className={styles.field}
          labelClassName={styles.fieldLabel}
          horizontal
          {...businessId}
        >
          <TextField
            className={styles.longField}
            {...businessId}
            onChange={onChangeBusinessIdCb}
            loading={loadingFleetDetails}
          />
        </Field>
        {fleet?.businessName && (
          <Field
            className={styles.field}
            labelClassName={styles.fieldLabel}
            horizontal
            {...businessName}
          >
            <Text style={{ color: "#34384b" }}>{fleet.businessName}</Text>
          </Field>
        )}

        <Field
          className={styles.field}
          labelClassName={styles.fieldLabel}
          horizontal
          {...cashInAmount}
        >
          <InputAmount innerLabel {...cashInAmount} onChange={modifyField} />
          <Text
            subtitle
            style={{
              marginTop: "5px",
            }}
          >
            <locale.Populate
              text={locale.amountToBeCreditedToPesoBalanceN}
              items={[
                <span
                  style={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "11px",
                  }}
                >
                  {formatAmount(cashInAmountTotalValue)}
                </span>,
              ]}
            />
          </Text>
        </Field>
        <Field
          className={styles.field}
          labelClassName={styles.fieldLabel}
          horizontal
          label={locale.cashInFeeLocq}
          {...cashInFeeLocq}
        >
          <InputAmount innerLabel {...cashInFeeLocq} onChange={modifyField} />
        </Field>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <Field
            style={{ width: "auto" }}
            className={styles.field}
            labelClassName={styles.fieldLabel}
            horizontal
            label={locale.cashInFeeCustomer}
            {...cashInFeeCustomer}
          >
            <InputAmount
              style={{ width: "100px" }}
              innerLabel
              {...cashInFeeCustomer}
              onChange={modifyField}
            />
            <Text subtitle style={{ width: "200px", marginTop: "5px" }}>
              {locale.populate(locale.manualBankDepositsAreChargedNPerTransactions, [
                formatAmount(parseNumber(cashInFeeCustomer.value)),
              ])}
            </Text>
          </Field>
          <div
            style={{ display: "flex", marginLeft: "30px", marginTop: "20px", alignItems: "center" }}
          >
            <Checkbox {...deductCustomerFee} onChange={modifyField} />
            <Text style={{ color: "#34384b" }}>{locale.deductCashinAmount}</Text>
          </div>
        </div>
        <Field
          className={styles.field}
          labelClassName={styles.fieldLabel}
          horizontal
          label={locale.cashInChannel}
          {...cashInChannel}
        >
          <Dropdown
            name="cashinChannel"
            className={styles.longField}
            onChange={(value, name) => {
              modifyField(name, { value });
            }}
            {...cashInChannel}
            options={[
              {
                label: prettifyCashInChannel(CashInChannel.SeaoilReciprocity),
                value: CashInChannel.SeaoilReciprocity,
              },
              {
                label: prettifyCashInChannel(CashInChannel.LocqRebate),
                value: CashInChannel.LocqRebate,
              },
              {
                label: prettifyCashInChannel(CashInChannel.ManualBankDeposit),
                value: CashInChannel.ManualBankDeposit,
              },
              {
                label: prettifyCashInChannel(CashInChannel.WithholdingTaxRefund),
                value: CashInChannel.WithholdingTaxRefund,
              },
              {
                label: prettifyCashInChannel(CashInChannel.ManualBankDepositSPI),
                value: CashInChannel.ManualBankDepositSPI,
              },
            ]}
          />
        </Field>
        <Field
          className={styles.field}
          labelClassName={styles.fieldLabel}
          horizontal
          label={locale.referenceNumber}
          {...referenceNumber}
        >
          <TextField className={styles.longField} {...referenceNumber} onChange={modifyField} />
        </Field>
        <Field className={styles.field} labelClassName={styles.fieldLabel} horizontal {...remarks}>
          <TextField
            className={styles.longField}
            {...remarks}
            onChange={(name, { value }) => {
              modifyField("remarks", { value });
            }}
          />
        </Field>
      </div>
      <div>
        <Button
          primary
          loading={creatingManualCashIn}
          disabled={!isFormSubmittable || loadingFleetDetails}
          onClick={() => {
            submitForm(confirm);
          }}
        >
          {locale.continue}
        </Button>
      </div>
    </div>
  );
};

export default ManualCashinModule;
