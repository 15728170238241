import { exportCSV, parseCSVFile } from "utils/object.utils";
import React from "react";
import ImportModal from "components/modals/import-modal/import-modal";
import { ActionButton, Modal, Text } from "components/commons";
import locale from "localization";
import { useModal } from "hooks";
import { isAmountValid } from "utils/number.utils";
import { parseNumber } from "utils";
import { kebabCase } from "lodash";
import { CashInChannelType } from "enums";

const CashInImportModal = ({
  request = {},
  submitCashInManual,
  batchManualCashInLoading,
  ...importModal
}) => {
  const resultModal = useModal();

  const validateFile = async ({ file }, onParse) => {
    const items = await parseCSVFile(file, [
      {
        text: locale.businessId,
        key: "businessId",
        validation: (text, row) => {
          if (text.toString().length > 0) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: `${locale.emptyBusinessId} ${row.referenceNumber}`,
              errorType: "businessId",
            };
          }
        },
      },
      {
        text: locale.cashInAmount,
        key: "cashInAmount",
        validation: (text, row) => {
          if (isAmountValid(text) && text.toString().length >= 1) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: `${locale.emptyCashInAmount}  ${row.referenceNumber}`,
              errorType: "cashInAmount",
            };
          }
        },
      },
      {
        text: locale.cashInFeeLocq,
        key: "cashInFeeLOCQ",
        validation: (text, row) => {
          if (isAmountValid(text) && text.toString().length >= 1) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: `${locale.emptyCashInFeeLOCQ}  ${row.referenceNumber}`,
              errorType: "cashInFee",
            };
          }
        },
      },
      {
        text: locale.cashInFeeCustomer,
        key: "cashInFeeCustomer",
        validation: (text, row) => {
          if (isAmountValid(text) && text.toString().length >= 1) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: `${locale.emptyCashInFeeCustomer}  ${row.referenceNumber}`,
              errorType: "cashInFeeCustomer",
            };
          }
        },
      },
      {
        text: locale.deductCashinAmountQuestion,
        key: "deductFromCashIn",
        validation: (text, row) => {
          if (text === "Y" || text === "N") {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: `${locale.emptyDeductFromCashInAmount}  ${row.referenceNumber}`,
              errorType: "deductFromCashIn",
            };
          }
        },
      },
      {
        text: locale.cashInChannelColumn,
        key: "cashInChannel",
        validation: (text, row) => {
          if (
            text === "Manual - Bank Deposit to SPI" ||
            text === "Manual - Bank Deposit" ||
            text === "Withholding Tax Refund" ||
            text === "LOCQ Rebate" ||
            text === "Seaoil Reciprocity"
          ) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: `${locale.emptyCashInChannel}  ${row.referenceNumber}`,
              errorType: "cashInChannel",
            };
          }
        },
      },
      {
        text: locale.referenceNumber,
        key: "referenceNumber",
        validation: (text, row) => {
          if (text.toString().length > 0) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: `${locale.emptyReferenceNumber} ${row.referenceNumber}`,
              errorType: "referenceNumber",
            };
          }
        },
      },
      {
        text: locale.remarks,
        key: "remarks",
        validation: (text, row) => {
          return {
            error: false,
            message: "",
          };
        },
      },
    ]);
    return items;
  };

  const handleLoadData = async (file) => {
    const parsedFile = await validateFile(file);
    console.log("parsedFile", parsedFile);
    const errors = [];
    if (parsedFile.hasBlankRows) {
      errors.push({
        title: locale.otherError,
        items: [locale.thereAreBlankRowsAndColumns],
      });
    }

    if (parsedFile?.invalidFormat) {
      resultModal.show({
        title: locale.manualCashInImportFailed,
        isSuccess: false,
        content: <ErrorImport errors={[locale.invalidCsvFileTypeFormat]} />,
      });
    } else if (parsedFile?.passed?.length > 0) {
      if (parsedFile?.errors?.length > 0) {
        resultModal.show({
          title: locale.manualCashInImportFailed,
          isSuccess: false,
          content: (
            <ErrorImport
              errors={parsedFile.errors}
              data={parsedFile.items}
              correctData={parsedFile.passed}
            />
          ),
        });
      } else {
        const validData = parsedFile.passed;

        let data = [];
        for (let i = 0; i < validData.length; i++) {
          const isDeductFromCashinAmount = validData[i].deductFromCashIn;

          let totalAmount = parseNumber(validData[i].cashInAmount);

          if (isDeductFromCashinAmount === "Y") {
            totalAmount =
              parseNumber(validData[i].cashInAmount) - parseNumber(validData[i].cashInFeeCustomer);
          }

          const cashInParams = {
            shortName: validData[i].businessId,
            referenceNumber: validData[i].referenceNumber,
            amount: totalAmount,
            locqFee: parseNumber(validData[i].cashInFeeLOCQ),
            customerFee: parseNumber(validData[i].cashInFeeCustomer),
            cashinChannel:
              kebabCase(validData[i].cashInChannel) === "manual-bank-deposit-to-spi"
                ? CashInChannelType.ManualBankDepositToSpi
                : kebabCase(validData[i].cashInChannel),
            isDeductFromCashin: validData[i].deductFromCashIn
              ? validData[i].deductFromCashIn === "Y"
                ? true
                : false
              : null,
            remarks: validData[i].remarks,
            status: "success",
          };

          data.push(cashInParams);
        }

        if (validData.length > 0 && data.length > 0) {
          submitCashInManual(data, importModal);
        }
      }
    } else {
      importModal.close();
      resultModal.show({
        title: locale.manualCashInImportFailed,
        content: (
          <ErrorImport
            errors={parsedFile.errors}
            data={parsedFile.items}
            correctData={parsedFile.passed}
          />
        ),
      });
    }
  };

  return (
    <div>
      <Modal
        {...resultModal}
        close={() => {
          resultModal.close();
        }}
      >
        {resultModal.content}
        <ActionButton
          center
          loading={batchManualCashInLoading}
          items={[
            {
              text: locale.retry,
              onClick: () => {
                resultModal.close();
                importModal.show();
              },
              primary: true,
            },
          ]}
        />
      </Modal>
      <ImportModal
        title={locale.importManualCashInByCSV}
        {...importModal}
        loading={batchManualCashInLoading}
        onSubmit={async (file) => {
          handleLoadData(file);
        }}
      >
        <Text
          subtitle
          style={{
            margin: "0px 0px 20px 0px",
          }}
        >
          {locale.followTheFollowingColumnOrderAndLeaveRow1}
          <div
            style={{
              margin: "20px 0px",
            }}
          ></div>
          <locale.Populate
            text={locale.downloadASampleTemplate}
            items={[
              <span
                colored
                style={{
                  color: "#753bbd",
                  fontWeight: "700",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  exportCSV({
                    rows: [
                      "Business ID,Cash In Amount,Cash In Fee (LOCQ),Cash In Fee (Customer),Deduct from the cash-in Amount? [Y/N],Cash In Channel,Reference Number,Remarks",
                      "B001,1000,0,10,Y,Manual - Bank Deposit,00377 LCK",
                    ],
                    filename: locale.manualCashInFileName,
                  });
                }}
              >
                {locale.sampleTemplate}
              </span>,
            ]}
          />
        </Text>
      </ImportModal>
    </div>
  );
};

const ErrorImport = ({ errors = [], data = [], correctData = [] }) => {
  console.log("errors", errors);
  const getRow = errors.map((item) => item.replace(/[^0-9]/g, ""));
  const checkIfHasData = getRow[0] !== "" ? getRow : [];
  return (
    <div
      style={{
        width: "420px",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Text
          style={{
            color: "black",
          }}
        >
          {locale.unableToImport}
        </Text>
      </div>
      {checkIfHasData.length > 0 ? (
        <>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "45%",
              }}
            >
              <Text subtitle>{locale.invalidEntries}</Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
                strong
              >
                {errors.length > 10
                  ? errors.length
                  : `${errors.length} (rows no. ${getRow.join(", ")})`}
              </Text>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text subtitle>{locale.otherError}</Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
                strong
              >
                {errors.slice(0, 5).join(", ")}
              </Text>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CashInImportModal;
