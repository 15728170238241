import { Autocomplete, Button, Field, Radio, Text, TextField } from "components/commons";
import ClaimMethod from "enums/claim-method";
import VoucherType from "enums/voucher-type";
import VoucherCampaignGroup from "enums/campaign-group";
import locale from "localization";
import React from "react";

import VoucherSkeleton from "./voucher-skeleton";
import {
  prettifyVoucherCampaignGroup,
  prettifyVoucherCampaignGroupPrefix,
  prettifyVoucherClaimMethod,
  prettifyVoucherType,
} from "utils/pretty.utils";
import { InputAmount, SelectStation } from "components/field";
import { useCallback } from "react";
import {
  validateVoucherAmount,
  validateVoucherValue,
  voucherAmountState,
  voucherValueState,
} from "./voucher-campaign.state";
import VoucherTypeSetting from "enums/voucher-type-setting";
import VoucherRecipients from "enums/voucher-recipients";
import RemoveItemButton from "components/commons/remove-item-button/remove-item-button";
import { Grid } from "@material-ui/core";
import { isNumeric, parseNumber } from "utils";
import UploadFile from "components/commons/upload-file/upload-file";
import VoucherDiscountType from "enums/voucher-discount-type";
import DateRange from "components/commons/date-range/date-range";
import { parseCSVFile } from "utils/object.utils";
import { capitalizeAll, isMobileNumberStrictValidV2, isNumber } from "utils/text.utils";
import { useApi, useModal } from "hooks";
import { ConfirmModal } from "components/modals";
import { getUserByMobileNumber } from "apis/user.api";
import { BilledTo } from "enums";
import { validateVoucherRecipient } from "apis";

const VoucherForm = ({ loading, title, form = {}, intro }) => {
  const { fields, modifyForm } = form;

  const confirmModal = useModal();
  const {
    campaignName = {},
    shortDescription = {},
    longDescription = {},
    campaignImage = {},
    voucherType = {},
    campaignGroup = {},
    voucherPrefix = {},
    campaignDuration = {},
    voucherValue = {},
    claimMethod = {},
    voucherTypeSetting = {},
    recipients = {},
    voucherAmount = {},
    uploadCSV = {},
    voucherValueMultiClaim = {},
    claimLimit = {},
    voucherDiscountValue = {},
    discountValue = {},
    discountCap = {},
    minimumSpend = {},
    requiredReferenceStation = {},
    voucherQuantity = {},
    quantity = {},
    billedTo = {},
    billedToOthers = {},
    remarks = {},
    employeeRemarks = {},
  } = fields || {};
  const validateMobileNumber = useApi({
    api: getUserByMobileNumber,
    handleOwnError: true,
  });

  const validateVoucherRecipientRequest = useApi({
    api: validateVoucherRecipient,
    handleOwnError: true,
  });

  const onChangeVoucherValue = useCallback(
    ({ index, amount, quantity }) => {
      const value = voucherValue.value || [];
      if (value[index]) {
        const currentValue = value[index];
        value[index] = {
          amount: {
            ...currentValue.amount,
            ...amount,
          },
          quantity: {
            ...currentValue.quantity,
            ...quantity,
          },
        };
        voucherValue.onChange(voucherValue.name, {
          value: value,
        });
      }
    },
    [voucherValue]
  );

  const onChangeUploadCsv = useCallback(
    async (name, value) => {
      const { value: file } = value;
      const csvValidation = [
        {
          text: "Mobile Number",
          key: "mobileNumber",
          validation: (text, row) => {
            const error = !isMobileNumberStrictValidV2(text);
            return {
              error,
              message:
                "There are errors in the file you uploaded. Kindly double-check the file and try again.",
            };
          },
        },
      ];

      if (voucherType.value === VoucherType.PesoVoucherCode) {
        csvValidation.push({
          text: "Voucher Value",
          key: "value",
          validation: (text, row) => {
            const error = !isNumber(text);
            return {
              error,
              message: "Invalid value",
            };
          },
        });
      }
      csvValidation.push({
        text: "Quantity",
        key: "amount",
        validation: (text, row) => {
          const error = !isNumber(text);
          return {
            error,
            message: "Invalid amount",
          };
        },
      });

      if (file.length <= 0) {
        modifyForm({
          uploadCSV: {
            value: [],
          },
        });
      } else {
        const validation = await parseCSVFile(
          file[0],
          csvValidation.filter((item) => item?.text)
        );

        if (
          recipients.value === VoucherRecipients.UploadCsv &&
          voucherTypeSetting.value === VoucherTypeSetting.AutoSeed &&
          [VoucherType.PesoVoucherCode, VoucherType.PesoDiscount].includes(voucherType.value)
        ) {
          const voucherValues = [];
          for (const i of validation.items) {
            voucherValues.push({
              mobileNumber: i.mobileNumber,
              voucherValue: Number(i.value),
              quantity: Number(i.amount),
            });
          }
          try {
            await validateVoucherRecipientRequest.request({
              voucherValues,
              voucherSubtype: VoucherTypeSetting.AutoSeed,
              voucherType: voucherType.value,
            });
          } catch (e) {
            const { errorCode } = e?.data || {};
            const errors = {
              [`${errorCode}`]: () => {
                validation.errors.push(errorCode);
              },
            };
            if (errors[errorCode]) {
              confirmModal.close();
              errors[errorCode]();
            } else {
              e.showError();
            }
          }
        }

        if (validation.errors.length) {
          confirmModal.show({
            content: (
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <Text
                  strong
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  There are errors in the file you uploaded. Kindly double-check the file and try
                  again.
                </Text>
                {/* {validation.errors.map((error, index) => {
                return <div key={index}>{error}</div>;
              })} */}
              </div>
            ),
            primary: {
              text: "OK",
              onClick: () => {
                confirmModal.close();
              },
            },
          });
        } else {
          uploadCSV.onChange(name, value);
        }
      }
    },
    [
      voucherType.value,
      modifyForm,
      recipients.value,
      voucherTypeSetting.value,
      validateVoucherRecipientRequest,
      confirmModal,
      uploadCSV,
    ]
  );

  const onChangeVoucherAmount = useCallback(
    ({ index, amount, quantity, mobileNumber }) => {
      const value = voucherAmount.value || [];
      if (value[index]) {
        const currentValue = value[index];
        value[index] = {
          amount: {
            ...currentValue.amount,
            ...amount,
          },
          quantity: {
            ...currentValue.quantity,
            ...quantity,
          },
          mobileNumber: {
            ...currentValue.mobileNumber,
            ...mobileNumber,
          },
        };
        voucherAmount.onChange(voucherAmount.name, {
          value: value,
        });
      }
    },
    [voucherAmount]
  );

  const onChangeVoucherType = useCallback(
    ({ voucherType, voucherTypeSetting }) => {
      const isAutoSeed = voucherTypeSetting.value === VoucherTypeSetting.AutoSeed;
      const showRecipients = isAutoSeed;
      const isMultiClaimGeneric = voucherTypeSetting.value === VoucherTypeSetting.MultiClaimGeneric;
      const isPesoDiscount = voucherType.value === VoucherType.PesoDiscount;
      const showVoucherQuantity =
        isPesoDiscount && voucherTypeSetting.value === VoucherTypeSetting.OneTimeClaim;
      const showVoucherAmount = isAutoSeed;
      const showVoucherValue =
        !isMultiClaimGeneric && voucherType.value === VoucherType.PesoVoucherCode && !isAutoSeed;
      // const showQuantity =
      //   isPesoDiscount && voucherTypeSetting.value === VoucherTypeSetting.OneTimeClaim;
      const showQuantity = false;
      modifyForm(
        {
          voucherType,
          voucherTypeSetting,
          recipients: {
            visible: showRecipients,
            value: VoucherRecipients.SelectedAccounts,
            isDirty: false,
          },
          claimMethod: {
            value: showRecipients || isPesoDiscount ? ClaimMethod.SeedInVoucherTray : "",
            validations: {
              ...claimMethod.validations,
              isRequired: !showRecipients && !isPesoDiscount,
            },
            isDirty: false,
            content:
              isPesoDiscount || isAutoSeed
                ? prettifyVoucherClaimMethod(ClaimMethod.SeedInVoucherTray)
                : null,
          },
          voucherValue: {
            canAdd: !showRecipients && !isMultiClaimGeneric,
            value: showVoucherValue
              ? voucherValue.value[0]
                ? [voucherValue.value[0]]
                : []
              : voucherValue.value || [],
            visible: showVoucherValue,
            validations: {
              ...voucherValue.validations,
              isRequired: showVoucherValue,
            },
            isDirty: false,
          },
          voucherAmount: {
            visible: showVoucherAmount,
            value: showVoucherAmount ? [voucherAmountState] : [],
            validations: {
              ...voucherAmount.validations,
              isRequired: showVoucherAmount,
            },
            isDirty: false,
          },
          uploadCSV: {
            value: [],
            visible: false,
            validations: {
              ...uploadCSV.validations,
              isRequired: false,
            },
            isDirty: false,
          },
          voucherValueMultiClaim: {
            value: "",
            validations: {
              ...voucherValueMultiClaim.validations,
              isRequired: isMultiClaimGeneric && !isPesoDiscount,
            },
            visible: isMultiClaimGeneric && !isPesoDiscount,
            isDirty: false,
          },
          claimLimit: {
            ...claimLimit,
            value: "",
            validations: {
              ...claimLimit.validations,
              isRequired: isMultiClaimGeneric,
            },
            visible: isMultiClaimGeneric,
            isDirty: false,
          },
          voucherDiscountValue: {
            value: "",
            visible: isPesoDiscount,
            validations: {
              isRequired: isPesoDiscount,
            },
            isDirty: false,
          },
          discountValue: {
            isDirty: false,
            value: "",
            validations: {
              ...discountValue.validations,
              isRequired: isPesoDiscount,
            },
          },
          discountCap: {
            value: "",
            visible: isPesoDiscount,
            validations: {
              ...discountCap.validations,
              isRequired: isPesoDiscount,
            },
            isDirty: false,
          },
          minimumSpend: {
            value: "",
            visible: isPesoDiscount,
            validations: {
              ...minimumSpend.validations,
              isRequired: isPesoDiscount,
            },
            isDirty: false,
          },
          voucherQuantity: {
            value: "",
            visible: showVoucherQuantity,
            validations: {
              ...voucherQuantity.validations,
              isRequired: showVoucherQuantity,
            },
            isDirty: false,
          },
          requiredReferenceStation: {
            isDirty: false,
            value: [],
            visible: isPesoDiscount,
            validations: {
              custom: [
                (value, field) => {
                  if (isPesoDiscount) {
                    const error = value.length === 0 && !field.isAll;
                    return {
                      error,
                      message: error ? "This field is required." : "",
                    };
                  }
                  return {
                    error: false,
                    message: "",
                  };
                },
              ],
            },
          },
          quantity: {
            isDirty: false,
            value: "",
            visible: showQuantity,
            validations: {
              ...quantity.validations,
              isRequired: showQuantity,
            },
          },
        },
        false
      );
    },
    [
      modifyForm,
      voucherValue,
      voucherAmount,
      uploadCSV,
      discountCap,
      minimumSpend,
      voucherQuantity,
      quantity,
      claimLimit,
      claimMethod,
      discountValue,
      voucherValueMultiClaim,
    ]
  );

  const onChangeBilledTo = useCallback(
    ({ billedTo }) => {
      modifyForm({
        billedTo: {
          ...billedTo,
        },
        billedToOthers: {
          validations: {
            isRequired: billedTo.value === BilledTo.Others,
          },
        },
      });
    },
    [modifyForm]
  );

  const onChangeVoucherGroup = useCallback(
    ({ value }) => {
      const campaignGroupValue = value?.toString();
      const voucherPrefix = prettifyVoucherCampaignGroupPrefix(campaignGroupValue) ?? "";

      modifyForm({
        campaignGroup: {
          value: campaignGroupValue,
          errorType: "",
        },
        voucherPrefix: {
          value: capitalizeAll(voucherPrefix),
          errorType: "",
        },
        employeeRemarks: {
          validations: {
            isRequired:
              value === VoucherCampaignGroup.SPIAllocation ||
              value === VoucherCampaignGroup.SPIAllowance,
          },
        },
      });
    },
    [modifyForm]
  );
  return (
    <div>
      <VoucherSkeleton
        intro={intro}
        title={title}
        loading={loading}
        fields={{
          campaignName: {
            ...campaignName,
            content: campaignName.content || (
              <TextField
                {...campaignName}
                onChange={(name, value) => {
                  campaignName.onChange(name, {
                    ...value,
                    errorType: "",
                  });
                }}
              />
            ),
          },
          shortDescription: {
            ...shortDescription,
            content: shortDescription.content || <TextField {...shortDescription} />,
          },
          longDescription: {
            ...longDescription,
            content: longDescription.content || <TextField {...longDescription} />,
          },
          campaignImage: {
            ...campaignImage,
            content: campaignImage.content || (
              <UploadFile
                {...campaignImage}
                acceptInvalid
                file={["png", "jpg", "jpeg"]}
                maxSizeMB={2}
              />
            ),
          },
          uploadCSV: {
            ...uploadCSV,
            content: uploadCSV.content || (
              <UploadFile
                {...uploadCSV}
                text="Upload"
                image={false}
                errorMessage="File type not accepted. Please upload a .csv file."
                onChange={onChangeUploadCsv}
                file={["csv"]}
                accept={{
                  "text/csv": [".csv"],
                }}
              />
            ),
          },
          recipients: {
            ...recipients,
            content: recipients.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                {...recipients}
                onChange={(name, { value }) => {
                  const showVoucherAmount = value === VoucherRecipients.SelectedAccounts;
                  const showVoucherValue =
                    voucherType.value === VoucherType.PesoVoucherCode &&
                    value === VoucherRecipients.All;
                  const showUploadCSV =
                    value === VoucherRecipients.UploadCsv ||
                    value === VoucherRecipients.UploadCsvWithUnregisteredUsers;

                  const showQuantity =
                    voucherType.value === VoucherType.PesoDiscount &&
                    value === VoucherRecipients.All;
                  modifyForm({
                    recipients: {
                      value,
                    },
                    quantity: {
                      value: "",
                      visible: showQuantity,
                      validations: {
                        ...quantity.validations,
                        isRequired: showQuantity,
                      },
                    },
                    voucherAmount: {
                      visible: showVoucherAmount,
                      value: showVoucherAmount ? [voucherAmountState] : [],
                      validations: {
                        ...voucherAmount.validations,
                        isRequired: showVoucherAmount,
                      },
                    },
                    voucherValue: {
                      visible: showVoucherValue,
                      value: [voucherValueState],
                      validations: {
                        ...voucherValue.validations,
                        isRequired: showVoucherValue,
                      },
                    },
                    uploadCSV: {
                      value: [],
                      visible: showUploadCSV,
                      validations: {
                        isRequired: showUploadCSV,
                      },
                    },
                  });
                }}
                options={[
                  // {
                  //   value: VoucherRecipients.All,
                  //   text: locale.all,
                  // },
                  {
                    value: VoucherRecipients.SelectedAccounts,
                    text: locale.selectedAccounts,
                  },
                  {
                    value: VoucherRecipients.UploadCsv,
                    text: `${locale.uploadCsv} (${locale.existingUsersOnly})`,
                  },
                  {
                    value: VoucherRecipients.UploadCsvWithUnregisteredUsers,
                    text: `${locale.uploadCsv} (${locale.includingUnregisteredNumbers})`,
                  },
                ]}
              />
            ),
          },
          voucherDiscountValue: {
            ...voucherDiscountValue,
            content: voucherDiscountValue.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                vertical
                {...voucherDiscountValue}
                onChange={(name, { value }) => {
                  const isPesoDiscount = value === VoucherDiscountType.Percent;
                  modifyForm({
                    [name]: { value },
                    discountCap: {
                      visible: isPesoDiscount,
                      validations: {
                        ...discountCap.validations,
                        isRequired: isPesoDiscount,
                      },
                    },
                    discountValue: {
                      value: "",
                    },
                  });
                  // voucherDiscountValue.onChange(name, { value });
                }}
                options={[
                  {
                    value: VoucherDiscountType.Percent,
                    text: locale.percentDiscount,
                  },
                  {
                    value: VoucherDiscountType.Specific,
                    text: locale.specificAmount,
                  },
                ].map((item, key) => {
                  return {
                    ...item,
                    content: (
                      <div
                        style={{
                          marginLeft: "30px",
                          width: "150px",
                        }}
                        key={key}
                      >
                        <Field {...discountValue} style={{ margin: "0px" }}>
                          <InputAmount
                            {...discountValue}
                            maxLength={
                              voucherDiscountValue.value === VoucherDiscountType.Percent ? 3 : null
                            }
                            onChange={(name, { value }) => {
                              if (
                                voucherDiscountValue.value === VoucherDiscountType.Specific ||
                                (voucherDiscountValue.value === VoucherDiscountType.Percent &&
                                  !value?.includes("."))
                              ) {
                                discountValue.onChange(name, { value });
                              }
                            }}
                            innerLabel
                            prefix={
                              voucherDiscountValue.value === VoucherDiscountType.Percent ? "%" : "₱"
                            }
                          />
                        </Field>
                      </div>
                    ),
                  };
                })}
              />
            ),
          },
          voucherType: {
            ...voucherType,
            content: voucherType.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                vertical
                {...voucherType}
                onChange={(name, { value }) => {
                  onChangeVoucherType({
                    voucherType: {
                      value,
                    },
                    voucherTypeSetting: {
                      value: VoucherTypeSetting.OneTimeClaim,
                    },
                  });
                }}
                options={[VoucherType.PesoVoucherCode, VoucherType.PesoDiscount].map(
                  (item, key) => {
                    return {
                      value: item,
                      text: prettifyVoucherType(item),
                      content: (
                        <div
                          style={{
                            marginLeft: "30px",
                          }}
                          key={key}
                        >
                          <Radio
                            style={{
                              width: "100%",
                              alignItems: "flex-start !important",
                            }}
                            vertical
                            {...voucherTypeSetting}
                            onChange={(name, { value }) => {
                              onChangeVoucherType({
                                voucherType: {
                                  value: voucherType.value,
                                },
                                voucherTypeSetting: {
                                  value,
                                },
                              });
                            }}
                            options={[
                              {
                                value: VoucherTypeSetting.OneTimeClaim,
                                text: locale.oneTimeClaim,
                              },
                              {
                                value: VoucherTypeSetting.AutoSeed,
                                text: locale.autoSeed,
                              },
                              {
                                value: VoucherTypeSetting.MultiClaimGeneric,
                                text: locale.multiClaimGeneric,
                              },
                            ]}
                          />
                        </div>
                      ),
                    };
                  }
                )}
              />
            ),
          },
          campaignGroup: {
            ...campaignGroup,
            content: campaignGroup.content || (
              <Autocomplete
                {...campaignGroup}
                options={[
                  VoucherCampaignGroup.Others,
                  VoucherCampaignGroup.SPIAllowance,
                  VoucherCampaignGroup.SPIAllocation,
                  VoucherCampaignGroup.SPISuppliers,
                  VoucherCampaignGroup.SPIHonorarium,
                ].map((item) => ({
                  value: item,
                  label: prettifyVoucherCampaignGroup(item),
                }))}
                onChange={(name, { value }) => {
                  onChangeVoucherGroup({ value });
                }}
              />
            ),
          },
          voucherPrefix: {
            ...voucherPrefix,
            content: voucherPrefix.content || (
              <TextField
                {...voucherPrefix}
                onChange={(name, value) => {
                  voucherPrefix.onChange(name, {
                    ...value,
                    value: capitalizeAll(value?.value.toString()),
                    errorType: "",
                  });
                }}
              />
            ),
          },
          employeeRemarks: {
            ...employeeRemarks,
            content: employeeRemarks.content || <TextField {...employeeRemarks} />,
            visible:
              campaignGroup.value === VoucherCampaignGroup.SPIAllocation ||
              campaignGroup.value === VoucherCampaignGroup.SPIAllowance,
          },
          campaignDuration: {
            ...campaignDuration,
            content: campaignDuration.content || (
              <div
                style={{
                  width: "250px",
                }}
              >
                <DateRange {...campaignDuration} />
              </div>
            ),
          },
          voucherValue: {
            ...voucherValue,
            content: voucherValue.content || (
              <div>
                {voucherValue.value?.map((value = {}, index) => {
                  const { amount = {}, quantity = {} } = value;
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "flex-start",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          width: "150px",
                        }}
                      >
                        <Field
                          {...amount}
                          labelPosition="left"
                          error={amount.error}
                          labelStyle={{
                            marginRight: "20px",
                          }}
                          style={{
                            margin: "10px 0px",
                          }}
                        >
                          <InputAmount
                            innerLabel
                            value={amount.value}
                            error={amount.error}
                            onChange={(name, { value }) => {
                              let hasError = "";
                              const val = parseNumber(value);
                              const isNumeric = amount.validation({ value });
                              if (isNumeric && val < 1) {
                                hasError = `Minimum of 1`;
                              }
                              if (isNumeric && val > 100000) {
                                hasError = `Maximum of 100,000`;
                              }
                              if (isNumeric || hasError || !value)
                                onChangeVoucherValue({
                                  amount: {
                                    value,
                                    error: Boolean(hasError),
                                    message: hasError,
                                  },
                                  quantity,
                                  index,
                                });
                            }}
                          />
                        </Field>
                      </div>
                      <div
                        style={{
                          width: "220px",
                          marginLeft: "20px",
                        }}
                      >
                        <Field
                          {...quantity}
                          labelPosition="left"
                          error={quantity.error}
                          label={locale.quantity}
                          labelStyle={{
                            marginRight: "20px",
                            marginTop: "10px",
                          }}
                          style={{
                            margin: "10px 0px",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <TextField
                            value={quantity.value}
                            error={quantity.error}
                            onChange={(name, { value }) => {
                              let hasError = "";
                              const val = !isNaN(parseInt(value)) ? parseNumber(value) : "";
                              if (value && val?.toString() && val < 1) {
                                hasError = `Minimum of 1`;
                              }
                              if (value && val && val > 100000) {
                                hasError = `Maximum of 100,000`;
                              }
                              if (!value || quantity.validation({ value }) || hasError)
                                onChangeVoucherValue({
                                  amount,
                                  quantity: {
                                    value,
                                    error: Boolean(hasError) || !value.trim(),
                                    message: hasError,
                                  },
                                  index,
                                });
                            }}
                          />
                        </Field>
                      </div>
                      {voucherValue.value.length > 1 && (
                        <RemoveItemButton
                          onClick={() => {
                            const items = [...voucherValue.value];
                            items.splice(index, 1);
                            voucherValue.onChange(voucherValue.name, {
                              value: [...items],
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                })}
                {voucherValue.canAdd && (
                  <Button
                    style={{
                      marginTop: "10px",
                    }}
                    outline
                    onClick={() => {
                      const error = validateVoucherValue(voucherValue.value);
                      if (!error) {
                        voucherValue.onChange(voucherValue.name, {
                          value: [...voucherValue.value, voucherValueState],
                        });
                      } else {
                        voucherValue.onChange(voucherValue.name, {
                          value: voucherValue.value.map((item) => {
                            return {
                              amount: {
                                ...item.amount,
                                error: !parseInt(item.amount.value),
                              },
                              quantity: {
                                ...item.quantity,
                                error: !parseInt(item.quantity.value),
                              },
                            };
                          }),
                        });
                      }
                    }}
                  >
                    {locale.addDenomination}
                  </Button>
                )}
              </div>
            ),
          },
          voucherAmount: {
            ...voucherAmount,
            content: voucherAmount.content || (
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Text subtitle>{locale.priceLocqAccount}</Text>
                  </Grid>
                  {voucherType.value === VoucherType.PesoVoucherCode && (
                    <Grid item xs={4}>
                      <Text subtitle>{locale.voucherValue}</Text>
                    </Grid>
                  )}

                  <Grid item xs={3}>
                    <Text subtitle>{locale.quantity}</Text>
                  </Grid>
                </Grid>
                {voucherAmount.value?.map((value = {}, index) => {
                  const { amount, quantity, mobileNumber } = value;
                  return (
                    <Grid container spacing={1} key={index}>
                      <Grid item xs={5}>
                        <Field
                          style={{
                            margin: "0px",
                          }}
                          error={mobileNumber.error}
                          message={mobileNumber.message}
                        >
                          <TextField
                            value={mobileNumber.value}
                            maxLength={10}
                            error={mobileNumber.error}
                            placeholder="format: 9192345678"
                            disabled={validateMobileNumber.loading}
                            onChange={(name, { value }) => {
                              if (mobileNumber.validation({ value }) || !value) {
                                const invalidMobileNumber = value.length !== 10 || value[0] !== "9";

                                onChangeVoucherAmount({
                                  amount,
                                  quantity,
                                  mobileNumber: {
                                    value,
                                    error: invalidMobileNumber,
                                    message:
                                      invalidMobileNumber && value
                                        ? "Please input a valid PriceLOCQ account"
                                        : "",
                                  },
                                  index,
                                });
                              }
                            }}
                            onBlur={async (name, { value }) => {
                              if (!mobileNumber.error) {
                                try {
                                  const res = await validateMobileNumber.request({
                                    mobileNumber: `0${value}`,
                                  });
                                  const invalid = ["deactivated", "deleted"].includes(
                                    res?.data?.status
                                  );
                                  onChangeVoucherAmount({
                                    amount,
                                    quantity,
                                    mobileNumber: {
                                      value,
                                      error: invalid,
                                      message: invalid
                                        ? "Please input a valid PriceLOCQ account"
                                        : "",
                                    },
                                    index,
                                  });
                                } catch (e) {
                                  const { errorCode } = e?.data || {};
                                  if (errorCode === "P1005") {
                                    onChangeVoucherAmount({
                                      amount,
                                      quantity,
                                      mobileNumber: {
                                        value,
                                        error: true,
                                        message: "Please input a valid PriceLOCQ account",
                                        errorType: "not-found",
                                      },
                                      index,
                                    });
                                  }
                                }
                              }
                            }}
                            prefix={
                              <div>
                                <Text
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  +63
                                </Text>
                              </div>
                            }
                          />
                        </Field>
                      </Grid>
                      {voucherType.value === VoucherType.PesoVoucherCode && (
                        <Grid item xs={4}>
                          <InputAmount
                            innerLabel
                            value={amount.value}
                            error={amount.error}
                            disabled={validateMobileNumber.loading}
                            onChange={(name, { value }) => {
                              let hasError = "";
                              const val = parseNumber(value);
                              const isNumeric = amount.validation({ value });
                              if (isNumeric && val < 1) {
                                hasError = `Minimum of 1`;
                              }
                              if (isNumeric && val > 100000) {
                                hasError = `Maximum of 100,000`;
                              }

                              if (isNumeric || hasError || !value)
                                onChangeVoucherAmount({
                                  amount: {
                                    value,
                                    error: Boolean(hasError),
                                    message: hasError,
                                  },
                                  quantity,
                                  mobileNumber,
                                  index,
                                });
                            }}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                        }}
                      >
                        <Field
                          {...quantity}
                          style={{
                            margin: "0px",
                          }}
                          labelStyle={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          <TextField
                            value={quantity.value}
                            error={quantity.error}
                            disabled={validateMobileNumber.loading}
                            onChange={(name, { value }) => {
                              let hasError = "";
                              const val = parseNumber(value);
                              const isNumeric = quantity.validation({ value });
                              if (isNumeric && val < 1) {
                                hasError = `Minimum of 1`;
                              }
                              if (isNumeric && val > 100000) {
                                hasError = `Maximum of 100,000`;
                              }
                              if (isNumeric || hasError || !value)
                                onChangeVoucherAmount({
                                  amount,
                                  quantity: {
                                    value,
                                    error: Boolean(hasError) || !value,
                                    message: hasError,
                                  },
                                  mobileNumber,
                                  index,
                                });
                            }}
                          />
                        </Field>
                        {voucherAmount.value.length > 1 && (
                          <RemoveItemButton
                            onClick={() => {
                              const items = [...voucherAmount.value];
                              items.splice(index, 1);
                              voucherAmount.onChange(voucherAmount.name, {
                                value: [...items],
                              });
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                {voucherAmount.canAdd && (
                  <Button
                    style={{
                      marginTop: "10px",
                    }}
                    outline
                    disabled={validateMobileNumber.loading}
                    onClick={() => {
                      const error = validateVoucherAmount(voucherAmount.value, {
                        isAmountRequired: voucherType.value === VoucherType.PesoVoucherCode,
                      });
                      if (!error) {
                        voucherAmount.onChange(voucherAmount.name, {
                          value: [...voucherAmount.value, voucherAmountState],
                        });
                      } else {
                        voucherAmount.onChange(voucherAmount.name, {
                          value: voucherAmount.value.map((item) => {
                            return {
                              amount: {
                                ...item.amount,
                                error:
                                  voucherType.value === VoucherType.PesoVoucherCode
                                    ? !parseInt(item.amount.value)
                                    : false,
                              },
                              quantity: {
                                ...item.quantity,
                                error: !parseInt(item.quantity.value),
                              },
                              mobileNumber: {
                                ...item.mobileNumber,
                                error:
                                  !isNumeric(item.mobileNumber.value) ||
                                  item.mobileNumber.value.length !== 10 ||
                                  item.mobileNumber.value[0] !== "9" ||
                                  item.mobileNumber.errorType === "not-found",
                              },
                            };
                          }),
                        });
                      }
                    }}
                  >
                    {locale.addRow}
                  </Button>
                )}
              </div>
            ),
          },
          voucherValueMultiClaim: {
            ...voucherValueMultiClaim,
            content: voucherValueMultiClaim.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <InputAmount innerLabel {...voucherValueMultiClaim} />
              </div>
            ),
          },
          voucherQuantity: {
            ...voucherQuantity,
            content: voucherQuantity.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <TextField {...voucherQuantity} />
              </div>
            ),
          },
          quantity: {
            ...quantity,
            content: quantity.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <TextField {...quantity} />
              </div>
            ),
          },
          claimLimit: {
            ...claimLimit,
            content: claimLimit.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <TextField {...claimLimit} />
              </div>
            ),
          },
          discountCap: {
            ...discountCap,
            content: discountCap.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <InputAmount {...discountCap} innerLabel />
              </div>
            ),
          },
          minimumSpend: {
            ...minimumSpend,
            content: minimumSpend.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <InputAmount {...minimumSpend} innerLabel />
              </div>
            ),
          },
          requiredReferenceStation: {
            ...requiredReferenceStation,
            content: requiredReferenceStation.content || (
              <SelectStation {...requiredReferenceStation} isPlcOnboarded />
              // <SelectReferenceStation {...requiredReferenceStation} />
            ),
          },
          claimMethod: {
            ...claimMethod,
            content: claimMethod.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                vertical
                {...claimMethod}
                options={[ClaimMethod.AutoCashIn, ClaimMethod.SeedInVoucherTray].map((item) => {
                  return {
                    value: item,
                    text: prettifyVoucherClaimMethod(item),
                  };
                })}
              />
            ),
          },
          billedTo: {
            ...billedTo,
            content: billedTo.content || (
              <>
                <Autocomplete
                  {...billedTo}
                  options={[
                    BilledTo.SeaOilFoundationInc,
                    BilledTo.SeaoilPhilippines,
                    BilledTo.JadePinnacleConsolidatedInc,
                    BilledTo.OceanTankersCorporation,
                    BilledTo.LocqOPC,
                    BilledTo.SupremeDominionConsolidatedInc,
                    BilledTo.SecurityBankCorporation,
                    BilledTo.ServCentralInc,
                    BilledTo.PlentinaLendingInc,
                    BilledTo.ELinkBusinessInnovation,
                    BilledTo.GlobalDominionFinancingIncorporated,
                    BilledTo.NovelCapitalInc,
                    BilledTo.ServCentralInc,
                    BilledTo.GazzUpInc,
                    BilledTo.EmeraldFieldsTradingInc,
                    BilledTo.FrancisGlennYu,
                    BilledTo.VirginiaGloriaBraga,
                    BilledTo.FrancisYu,
                    BilledTo.Others,
                  ].map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  onChange={(name, { value }) => {
                    onChangeBilledTo({
                      billedTo: {
                        value,
                      },
                    });
                  }}
                />
                {billedTo.value === BilledTo.Others && (
                  <Field>
                    <TextField {...billedToOthers}></TextField>
                  </Field>
                )}
              </>
            ),
          },
          remarks: {
            ...remarks,
            content: remarks.content || <TextField {...remarks} />,
          },
        }}
      />
      <ConfirmModal {...confirmModal} />
    </div>
  );
};

export default VoucherForm;
