import React, { useContext, useMemo } from "react";
import { Intro, Text } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { getMerchantPayments, voidMerchantPayment } from "apis/non-fuel.api";
import { nonFuelFilterStatePLC } from "./plc/non-fuel-filter.state";
import { mapDataToListPLC, mapFilterToRequestPLC } from "./plc/non-fuel.mapper";
import { nonFuelColumnsPLC } from "./plc/non-fuel-columns";
import { useApi, useExport, useModal, useRouter } from "hooks";
import { generateReportAdmin } from "apis/generate-report.api";
import { SuccessModal, ConfirmModal } from "components/modals";
import { PlatformType, SettlementStatus, UserAccess } from "enums";
import { formatAmount } from "utils";
import { useCallback } from "react";
import { useState } from "react";
import ActionsHistoryModal from "./actions-history-modal";
import { UserContext } from "contexts";
import { nonFuelColumnsPLB } from "./plb/non-fuel-columns";
import { mapDataToListPLB, mapFilterToRequestPLB } from "./plb/non-fuel.mapper";
import NonFuelFilterPLC from "./plc/non-fuel-filter";
import NonFuelFilterPLB from "./plb/non-fuel-filter";
import { nonFuelFilterStatePLB } from "./plb/non-fuel-filter.state";
import VoidPaymentModalPLC from "./plc/void-non-fuel-modal";
import VoidPaymentModalPLB from "./plb/void-payment-modal";

const NonFuelModule = () => {
  const { sessionId, userAccess } = useContext(UserContext);
  const voidPaymentModal = useModal();
  const actionsHistoryModal = useModal();
  const confirmModal = useModal();

  const getUserAccess = userAccess.find((item) => {
    return item.key === UserAccess.PLCNonFuel || item.key === UserAccess.PLBNonFuel;
  });

  const { pathname } = useRouter();
  const splittedPathname = pathname.split("/");
  const platformType = splittedPathname[splittedPathname.length - 1];

  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState("");

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidMerchantPayment,
    pageError: false,
    modalError: true,
  });

  const handleActionsHistory = ({ voidedByEmail, status, voidedAt }) => {
    actionsHistoryModal.show({
      title: locale.actionsHistory,
      voidedByEmail,
      status,
      voidedAt,
    });
  };

  const onVoidPaymentCb = useCallback(
    async ({ merchantPaymentId, value, amount }) => {
      await voidRequest({ merchantPaymentId, remarks: value });
      voidPaymentModal.close();
      table.fetch({});

      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisPaymentHasBeenVoided}
            items={[<b>{formatAmount(amount)}</b>]}
          />
        ),
        loading: voidingRequest,
        primary: {
          text: locale.gotIt,
          onClick: () => {
            confirmModal.close();
          },
        },
      });
      setRemarks("");
    },
    //eslint-disable-next-line
    [voidRequest, voidPaymentModal, confirmModal, voidingRequest]
  );

  const {
    FilterComponent,
    VoidPaymentModal,
    columns,
    handleVoidPayment,
    mapFilterToRequest,
    mapper,
    nonFuelFilterState,
  } = useMemo(() => {
    const isPLC = platformType === PlatformType.PLC;

    const columns = isPLC ? nonFuelColumnsPLC : nonFuelColumnsPLB;
    const mapper = isPLC ? mapDataToListPLC : mapDataToListPLB;
    const FilterComponent = isPLC ? NonFuelFilterPLC : NonFuelFilterPLB;
    const mapFilterToRequest = isPLC ? mapFilterToRequestPLC : mapFilterToRequestPLB;
    const nonFuelFilterState = isPLC ? nonFuelFilterStatePLC : nonFuelFilterStatePLB;
    const VoidPaymentModal = isPLC ? VoidPaymentModalPLC : VoidPaymentModalPLB;

    const handleVoidPayment = ({
      merchantPaymentId,
      amount,
      rebate,
      settlementId,
      settlementStatus,
    }) => {
      setRemarks("");

      const descriptionText =
        isPLC && settlementId
          ? settlementStatus === SettlementStatus.ForProcessing
            ? locale.voidingThisTransactionSettlementProcessingNonFuel
            : locale.voidingThisTransactionSettlementProcessedNonFuel
          : isPLC
          ? locale.voidingThisPayment
          : locale.voidingThisNonFuel;

      voidPaymentModal.show({
        title: `${locale.voidTransaction}?`,
        description: (
          <Text align="center">
            <locale.Populate
              text={descriptionText}
              items={[<b>{formatAmount(amount)}</b>, <b>{formatAmount(rebate)}</b>]}
            />
          </Text>
        ),
        label: locale.reasonRemarks,
        actionText: locale.voidTransaction,
        submit: (value) => {
          onVoidPaymentCb({
            merchantPaymentId,
            value,
            amount,
          });
        },
      });
    };

    return {
      columns,
      mapper,
      FilterComponent,
      mapFilterToRequest,
      nonFuelFilterState,
      VoidPaymentModal,
      handleVoidPayment,
    };
  }, [onVoidPaymentCb, platformType, voidPaymentModal]);

  const newColumns =
    getUserAccess?.fullAccess || getUserAccess?.modifyAccess
      ? columns
      : columns.filter((column) => column.key !== "action");

  const { filter, search, table } = useDataTable({
    api: { api: getMerchantPayments, params: { platformType: platformType } },
    filter: { initialState: nonFuelFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "merchantPayments",
      mapper: (merchantPayments) =>
        mapper({ merchantPayments, handleVoidPayment, handleActionsHistory, getUserAccess }),
      columns: newColumns,
    },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "merchant-payment",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      merchantPaymentStatuses: filter?.mappedFilterState.status,
      searchKey: search?.searchKey,
      platformType: platformType === PlatformType.PLC ? PlatformType.PLC : PlatformType.PLB,
      redemptionStationIds: filter?.mappedFilterState?.redemptionStationIds,
      connectionId: sessionId,
    },
  });

  delete table.result.count;

  return (
    <>
      <SuccessModal {...exports?.successModalComponent} />
      <ConfirmModal {...confirmModal} />
      <div>
        <Intro
          title={
            platformType === PlatformType.PLC
              ? locale.locqpayForNonFuelPLC
              : locale.locqpayForNonFuelPLB
          }
          subtitle={locale.viewLocqpayForNonfuelTransactionsPLC}
        />
      </div>
      <FilterComponent {...filter} {...search} {...exports} />
      <DataTableV2 {...table} />
      <ActionsHistoryModal {...actionsHistoryModal} />
      <VoidPaymentModal
        {...voidPaymentModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          if (v.length <= 355) {
            setRemarks(v);
          }
        }}
        loading={voidingRequest}
        onSubmit={voidPaymentModal.submit}
        value={value}
        setValue={setValue}
      />
    </>
  );
};

export default NonFuelModule;
